@import url("https://use.typekit.net/jnd5eis.css");
.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-list {
  margin: 0 -10px; }

.slick-slide {
  margin: 0 10px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer; }

.slick-slide img {
  margin: 0 auto; }

/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #000;
  font-size: 19px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: objektiv-mk3, sans-serif;
  font-weight: 300;
  max-width: 2560px;
  margin: 0 auto;
  letter-spacing: 0;
  overflow-x: hidden; }

button {
  background-color: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 120px 0; }

.container {
  max-width: 1140px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-title {
  font-size: 40px;
  line-height: 1;
  color: #000;
  margin-bottom: 16px;
  font-family: acier-bat-solid, sans-serif; }
  @media (max-width: 768px) {
    .section-title {
      font-size: 32px; } }
  @media (max-width: 600px) {
    .section-title {
      font-size: 24px;
      line-height: 1.1; } }

.sub-heading {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 1px;
  color: #000; }

strike {
  color: rgba(136, 136, 136, 0.7); }

s {
  color: #000; }

.img-container {
  text-align: center;
  line-height: 1; }

.section-header {
  margin-bottom: 16px; }

.sale-text {
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  margin: 20px 0 30px;
  color: #000; }
  .sale-text span {
    text-decoration: line-through;
    color: #c9c9c9;
    font-weight: 400; }

.bundle-price {
  font-size: 24px; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 8px 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  font-size: 17px;
  vertical-align: middle;
  box-shadow: none !important;
  outline: 0;
  text-transform: uppercase;
  font-family: acier-bat-solid, sans-serif;
  letter-spacing: 1px;
  border-radius: 30px;
  min-width: 250px; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #000;
  background-color: #000;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: #5b0870;
  border: 2px solid #5b0870;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

strong {
  color: #000; }

.link-color {
  color: #030303;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .link-color:hover {
    color: #5b0870;
    opacity: 0.8; }

.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  -webkit-box-align: center;
  align-items: center; }

@media only screen and (max-width: 1199px) {
  section {
    padding: 100px 0; } }

@media only screen and (max-width: 767px) {
  section {
    padding: 60px 0; }
  .sub-heading {
    font-size: 17px; } }

@media only screen and (max-width: 600px) {
  body {
    font-size: 16px; } }

@media only screen and (max-width: 375px) {
  .section-title {
    font-size: 24px; } }

.text-style {
  font-family: "Wild Spirit";
  color: #5b0870;
  font-size: 100px;
  line-height: 1; }

.section-header {
  margin-bottom: 50px; }
  .section-header .img-container {
    margin-bottom: 10px; }
  @media only screen and (max-width: 600px) {
    .section-header {
      margin-bottom: 40px; } }

.lines {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }
  .lines .img-container {
    margin: 0; }
  .lines .sub-heading {
    padding: 0 20px; }

/**********************
Navigation
**********************/
.announcement-bar {
  background-color: #5b0870;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px; }

@media only screen and (max-width: 991px) {
  .announcement-bar {
    position: absolute;
    width: 100%;
    top: 70px;
    padding: 10px 20px; } }

@media only screen and (max-width: 600px) {
  .announcement-bar {
    font-size: 13px; } }

header {
  background-color: #fff;
  box-shadow: none;
  height: auto;
  padding: 0 0 10px; }
  header .container {
    height: 100%; }
  header .link-centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  header .svg-inline--fa {
    font-size: 20px;
    margin-right: 5px; }
  header #main-nav {
    padding: 16px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%; }
    header #main-nav ul.menu-list {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
    header #main-nav .navbar-brand {
      max-width: 150px; }
  header .border-text {
    color: black;
    -webkit-text-fill-color: black;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white; }
  header .buy-now {
    background-color: #fff;
    border: 2px solid #fff;
    color: #000 !important;
    text-transform: uppercase;
    border-radius: 30px; }

.nav-link,
.nav-link:active,
.nav-link:focus,
.nav-link:hover,
.nav-link:visited {
  color: #fff;
  font-size: 17px;
  outline: 0;
  padding: 10px 15px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-family: acier-bat-solid, sans-serif;
  letter-spacing: 2px; }
  .nav-link.buy-now,
  .nav-link:active.buy-now,
  .nav-link:focus.buy-now,
  .nav-link:hover.buy-now,
  .nav-link:visited.buy-now {
    padding: 5px 25px !important; }

.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #5b0870;
  height: 100%;
  padding: 0; }

.navbar-brand {
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }

.cta-btn {
  background-color: #5b0870; }

.cta-btn .nav-link {
  color: #fff !important; }

@media only screen and (max-width: 991px) {
  header {
    height: 70px; } }

/* Mobile Menu */
header {
  background-color: #000;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  #main-nav {
    display: none !important; }
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%;
    text-transform: uppercase;
    text-transform: inherit; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #5b0870;
    max-width: 175px; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 25px;
    right: 30px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #000;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    display: block;
    color: #fff;
    font-size: 25px;
    outline: 0;
    padding: 12px !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    font-family: acier-bat-solid, sans-serif;
    letter-spacing: 1.5px; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000;
    height: 100%;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
    .mobile-menu .navbar-brand img {
      height: auto; }
  .mobile-menu input + label {
    right: 20px; }
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 19px; } }

/******************************
Hero 
******************************/
.hero {
  background: url(../img/header-hero.jpg) no-repeat;
  background-size: cover;
  background-position: center right;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 900px;
  position: relative;
  color: #fff;
  margin-top: 80px; }
  .hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .hero .container {
    position: relative;
    z-index: 1; }
  .hero .section-title {
    color: #fff;
    font-size: 52px; }
  .hero .btn-prime {
    color: #000;
    background-color: #fff;
    border-color: #fff; }

@media (max-width: 2000px) {
  .hero {
    height: 600px; } }

@media (max-width: 1199px) {
  .hero::after {
    background-color: rgba(0, 0, 0, 0.4); }
  .hero-content {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 700px; } }

@media (max-width: 991px) {
  .hero {
    background: url(../img/header-hero-mobile.jpg) no-repeat center;
    background-size: cover;
    margin-top: 70px; } }

@media (max-width: 768px) {
  .hero {
    height: 500px; }
  .hero .section-title {
    font-size: 40px; } }

@media (max-width: 600px) {
  .hero::after {
    background-color: rgba(0, 0, 0, 0.6); }
  .hero .section-title {
    font-size: 26px; } }

.songs {
  text-align: center;
  background: url(../img/songs-bg.jpg) no-repeat center center;
  background-size: cover;
  padding-bottom: 0; }
  .songs .img-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 0 auto; }

@media only screen and (min-width: 601px) {
  .phone-mobile {
    display: none; } }

@media only screen and (max-width: 600px) {
  .phone-desktop {
    display: none; }
  .songs {
    padding: 60px 0; }
  .songs .img-container {
    max-width: 350px; } }

.video .grid-row {
  grid-template-columns: 60% 40%;
  gap: 50px; }

.video .section-header {
  margin-bottom: 16px; }
  .video .section-header .section-title {
    font-size: 34px; }

.video .cta-container {
  margin-top: 20px; }

.video .main-video .video-container {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }

.video .main-video .img-container {
  position: relative; }
  .video .main-video .img-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.1;
    border-radius: 20px; }
  .video .main-video .img-container img {
    border-radius: 20px; }

.video .main-video .play-btn {
  position: absolute;
  max-width: 100px;
  z-index: 1; }

@media only screen and (max-width: 1400px) {
  .video .grid-row {
    grid-template-columns: 58% 36%; } }

@media only screen and (max-width: 1400px) and (min-width: 992px) {
  .video .grid-row .section-title {
    font-size: 32px; } }

@media only screen and (max-width: 991px) {
  .video .grid-row {
    grid-template-columns: 1fr;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    gap: 40px; }
  .video .main-video .img-container {
    margin-bottom: 0; } }

@media only screen and (max-width: 600px) {
  .video .section-header .section-title {
    font-size: 24px; } }

.video-popup {
  text-align: center; }
  .video-popup .section-header {
    margin-bottom: 40px; }
  .video-popup .grid-row {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px; }
    .video-popup .grid-row .graphic-section .play-btn {
      max-width: 70px; }
  .video-popup .cta-container {
    margin-top: 40px; }
  .video-popup .main-video .video-container {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
  .video-popup .main-video .img-container {
    position: relative; }
    .video-popup .main-video .img-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.1;
      border-radius: 20px; }
    .video-popup .main-video .img-container img {
      border-radius: 20px; }
  .video-popup .main-video .play-btn {
    position: absolute;
    max-width: 100px;
    z-index: 1; }

@media only screen and (max-width: 600px) {
  .video-popup .main-video .play-btn {
    max-width: 70px; }
  .video-popup .grid-row .graphic-section .play-btn {
    max-width: 30px; }
  .video-popup .grid-row {
    gap: 16px; } }

.movement {
  text-align: center;
  background-color: #F0F0F0; }
  .movement .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px; }
  .movement .cta-container {
    margin-top: 40px; }

@media only screen and (max-width: 768px) {
  .movement .image-grid {
    text-align: center;
    grid-template-columns: repeat(2, 1fr); }
    .movement .image-grid img {
      width: 100%; } }

/**********************
about
**********************/
.about {
  background-color: #000;
  color: #fff;
  position: relative; }
  .about .container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1; }
  .about .section-header {
    margin: 0; }
  .about .section-title {
    color: #fff; }
  .about .sub-heading {
    color: #fff;
    margin-bottom: 20px; }
  .about .btn-prime {
    background-color: #fff;
    border-color: #fff;
    color: #000;
    margin-top: 10px; }
  .about .grid-row {
    grid-template-columns: 55% 45%;
    gap: 0; }
    .about .grid-row .item img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden; }

@media only screen and (max-width: 1199px) {
  .about {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse; }
  .about .grid-row {
    grid-template-columns: 1fr;
    gap: 0; }
  .text-area {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    max-width: 800px;
    margin: 0 auto; }
  .about .container {
    position: relative;
    top: inherit;
    left: inherit;
    -webkit-transform: translate(-50%, -50%);
    transform: none;
    z-index: 1;
    text-align: center; } }

/**********************
animate
**********************/
.animate {
  text-align: center; }
  .animate .section-header {
    margin-bottom: -20px; }
  .animate .grid-row {
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    -webkit-box-align: start;
    align-items: start; }
    .animate .grid-row .img-container {
      height: 100%; }
    .animate .grid-row .item {
      text-align: center; }
      .animate .grid-row .item .title {
        text-transform: uppercase;
        font-family: acier-bat-solid, sans-serif;
        margin-bottom: 10px;
        font-size: 24px; }
      .animate .grid-row .item .img-container {
        margin-bottom: 30px; }
  .animate .mobile,
  .animate .tablet {
    display: none; }

@media only screen and (max-width: 991px) {
  .animate .section-header {
    margin-bottom: 50px; }
  .animate .desktop {
    display: none; }
  .animate .tablet {
    display: block; } }

@media only screen and (max-width: 600px) {
  .animate .tablet {
    display: none; }
  .animate .mobile {
    display: block; }
  .animate .img-container {
    max-width: 400px;
    margin: 0 auto; } }

/**********************
reviews
**********************/
.reviews {
  background-image: url("../img/reviews-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  color: #fff;
  overflow-x: hidden;
  text-align: center; }
  .reviews::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #23032b;
    opacity: 0.75; }
  .reviews .container-fluid {
    position: relative;
    z-index: 1;
    max-width: 1600px; }
  .reviews .flex-container {
    max-width: 1140px;
    margin: 0 auto; }
  .reviews .grid-row {
    gap: 0;
    grid-template-columns: 1fr;
    max-width: 800px;
    margin: 0 auto; }
    .reviews .grid-row .text-area .name {
      text-transform: uppercase;
      font-size: 30px;
      font-family: acier-bat-solid, sans-serif; }
  .reviews .section-header {
    text-align: center; }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }

.mobile-arrows img {
  max-width: 50px; }

.mobile-arrow-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 130px;
  margin: 0 auto; }
  .mobile-arrow-area .img-container {
    max-width: 50px;
    margin: 20px auto 0; }

@media only screen and (max-width: 991px) {
  .reviews {
    text-align: center; }
    .reviews .section-header {
      margin-bottom: 0; }
    .reviews .mobile-arrow-area {
      margin-top: 40px; }
  .reviews .grid-row {
    grid-template-columns: 1fr; }
  .reviews .img-container {
    margin-bottom: 30px; } }

@media only screen and (min-width: 992px) {
  .mobile-arrow-area {
    display: none; } }

@media only screen and (max-width: 991px) {
  .mobile-arrows {
    display: none; }
  .mobile-arrow-area .img-container {
    margin: 0 auto; } }

/**********************
Buy Stream
**********************/
.purchase {
  text-align: center;
  background-image: url("../img/purchase-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-attachment: fixed; }
  .purchase::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #23032b;
    opacity: 0.35; }
  .purchase .container {
    position: relative;
    z-index: 1; }
  .purchase .section-header {
    margin-bottom: 40px; }
    .purchase .section-header .section-title,
    .purchase .section-header .sub-heading {
      color: #fff; }
  .purchase .grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    -webkit-box-align: inherit;
    align-items: inherit; }
    .purchase .grid-row .item {
      padding: 50px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      background-color: #fff;
      border-radius: 50px;
      margin-bottom: 5px; }
      .purchase .grid-row .item .price {
        font-size: 28px;
        color: #5b0870;
        margin-bottom: 20px;
        font-weight: 700; }
        .purchase .grid-row .item .price span {
          color: #9c9c9c;
          position: relative; }
          .purchase .grid-row .item .price span .bar {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 100%;
            height: 3px;
            background-color: #5b0870; }
      .purchase .grid-row .item .cancel {
        display: block;
        color: #5b0870 !important;
        font-size: 15px;
        margin-top: 10px;
        margin-top: 10px;
        max-width: 300px;
        line-height: 1.6; }
      .purchase .grid-row .item .cancel-anytime {
        margin-top: 10px;
        color: #000; }
      .purchase .grid-row .item .des {
        background-color: #fff; }
        .purchase .grid-row .item .des .inner {
          padding: 30px 0; }
          .purchase .grid-row .item .des .inner .top-text {
            font-family: acier-bat-solid, sans-serif;
            color: #000;
            margin-bottom: 16px; }
      .purchase .grid-row .item .cta-container {
        margin-top: auto; }
  .purchase .in-person {
    margin-top: 50px;
    max-width: 600px;
    margin: 50px auto 0;
    background-color: #5b0870;
    color: #5b0870;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    -webkit-box-align: center;
    align-items: center;
    padding: 50px;
    border-radius: 50px; }
    .purchase .in-person h4 {
      color: #fff;
      line-height: 1.3;
      margin-bottom: 16px; }
      .purchase .in-person h4 span {
        display: block; }
    .purchase .in-person .in-person-text {
      text-align: left;
      color: #fff; }
      .purchase .in-person .in-person-text h4 {
        font-family: acier-bat-solid, sans-serif;
        margin-bottom: 5px;
        font-weight: 700;
        letter-spacing: 2px; }
      .purchase .in-person .in-person-text .btn-prime {
        color: #5b0870;
        background-color: #fff;
        border-color: #fff; }

@media only screen and (max-width: 991px) {
  .purchase .grid-row {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    max-width: 450px;
    margin: 0 auto; }
  .purchase .grid-row .item {
    padding: 50px 20px; }
  .purchase .grid-row .item .des .inner {
    padding: 20px 0; }
  .purchase .section-header .section-title {
    margin-bottom: 0; } }

@media only screen and (max-width: 600px) {
  .purchase {
    background-attachment: scroll; }
  .purchase .in-person {
    grid-template-columns: 1fr;
    gap: 20px; }
  .purchase .in-person .in-person-text {
    text-align: center; } }

.quote {
  text-align: center; }
  .quote .section-title {
    margin-bottom: 0; }

/**********************
Footer
**********************/
footer {
  background-color: #000;
  color: #fff;
  padding: 40px 0;
  font-weight: 500; }
  footer .brand {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
    footer .brand .img-container {
      text-align: left;
      max-width: 175px; }
  footer .foot-item {
    font-family: acier-bat-solid, sans-serif;
    font-weight: 700; }
    footer .foot-item .links {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
  footer a {
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    footer a:hover {
      color: #030303; }
  footer .footer_title {
    text-transform: uppercase;
    color: #030303;
    margin-bottom: 10px;
    font-family: acier-bat-solid, sans-serif;
    font-weight: 700; }
  footer .social-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  footer .copyright {
    margin-top: auto;
    text-transform: uppercase;
    font-size: 13px; }
  footer .footer-menu-link {
    text-align: center;
    font-size: 14px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
    footer .footer-menu-link a:hover {
      color: #fff;
      opacity: .8; }
    footer .footer-menu-link a:not(:last-child) {
      margin-bottom: 8px; }
  footer .social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end; }
    footer .social svg, footer .social img {
      margin: 0 7px;
      font-size: 18px;
      color: #fff;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      footer .social svg:hover, footer .social img:hover {
        color: #030303; }

@media only screen and (max-width: 767px) {
  footer .brand {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
    flex-direction: inherit;
    margin-bottom: 30px; }
  footer .social {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
    flex-direction: inherit;
    margin-top: 30px;
    -webkit-box-align: center;
    align-items: center; } }

@media only screen and (max-width: 575px) {
  .foot-item {
    margin-bottom: 20px; }
  footer {
    text-align: center; }
    footer .branding {
      text-align: center; }
  footer .brand .img-container {
    text-align: center; }
  footer .social {
    -webkit-box-align: center;
    align-items: center; } }

.cancelations {
  margin-top: 80px;
  padding-left: 15px;
  padding-right: 15px; }
  .cancelations .section-header {
    text-align: center; }

/** MEDIA QUERIES **/
/******************************
Mobile - Landscape
******************************/
